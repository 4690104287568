import { PageProps } from 'gatsby'
import { SortState, Standings as StandingsData } from 'src/common/types'
import React, { useEffect, useContext, useState, useRef } from 'react'
import ResizeObserverPolyfill from 'resize-observer-polyfill'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import color from 'color'
import { LayoutContext } from 'src/features/layout'
import WallpaperIcon from '@material-ui/icons/Wallpaper'
import ViewListIcon from '@material-ui/icons/ViewList'
import Slide from '@material-ui/core/Slide'
import SEO from 'src/common/seo'
import StandingsTable from 'src/features/standings/standings-table'
import StandingsBackground from 'src/features/standings/standings-background'
import Fab from 'src/common/components/fab'
import { dodgeFab, Card, StyledLink, spacing } from 'src/common/styles'
import { dashedBorder } from 'src/features/standings/styles'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

export type TransDirection = {
  east: 'left' | 'down'
  west: 'right' | 'up'
}

const Standings = ({ location }: PageProps) => {
  const result = useStaticQuery<any>(query)
  const standingsData = result.allStandings.nodes[0] as StandingsData
  const containerRef = useRef<HTMLDivElement>(null)
  // const groupProps = useMotionNavOffsetStyle(
  //   { cssProp: 'padding' },
  //   {
  //     dodge: 'none',
  //   }
  // )
  const props = useMotionNavOffsetStyle()
  const { setLocation } = useContext(LayoutContext)
  const [areTablesShown, setAreTablesShown] = useState(true)
  const [areTableHorizontal, setAreTablesHorizontal] = useState(true)
  const [sorting, setSorting] = useState<SortState>({
    value: 'ppg',
    isDesc: true,
  })

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    setAreTablesShown((shown) => !shown)
  }

  useEffect(() => {
    //ensuring that the element referred to is the same over time
    const currentRef = containerRef.current

    let ro: ResizeObserver

    try {
      ro = new ResizeObserver((entries) => {
        const children = entries[0].target.children
        const areEqual =
          children.item(0)?.getBoundingClientRect().y ===
          children.item(1)?.getBoundingClientRect().y

        setAreTablesHorizontal(areEqual)
      })
    } catch (err) {
      //Basically just mobile Firefox
      ro = new ResizeObserverPolyfill((entries) => {
        const children = entries[0].target.children
        const areEqual =
          children.item(0)?.getBoundingClientRect().y ===
          children.item(1)?.getBoundingClientRect().y

        setAreTablesHorizontal(areEqual)
      })
    }

    currentRef && ro.observe(currentRef)

    return () => {
      currentRef && ro.unobserve(currentRef)
    }
  }, [])

  const westTransitionDirection = areTableHorizontal ? 'right' : 'up'
  const eastTransitionDirection = areTableHorizontal ? 'left' : 'down'

  return (
    <>
      <SEO title="Standings" />
      <StandingsBackground />
      <Wrapper>
        {/* <GroupCardWrapper {...groupProps}>
          <GroupStageLink>
            <StyledLink to="/mib">Go to group stage standings</StyledLink>
          </GroupStageLink>
        </GroupCardWrapper> */}
        <Container {...props} ref={containerRef}>
          <Slide
            in={areTablesShown}
            appear={false}
            direction={westTransitionDirection}
          >
            <StandingsTable
              division="west"
              standingsData={standingsData['west']}
              sortCategory={sorting}
              setSortCategory={setSorting}
              rowCss={westRowCss}
            />
          </Slide>
          <Slide
            in={areTablesShown}
            appear={false}
            direction={eastTransitionDirection}
          >
            <StandingsTable
              division="east"
              standingsData={standingsData['east']}
              sortCategory={sorting}
              setSortCategory={setSorting}
              rowCss={eastRowCss}
            />
          </Slide>
        </Container>
      </Wrapper>
      <Fab onClick={handleClick}>
        {areTablesShown ? <WallpaperIcon /> : <ViewListIcon />}
      </Fab>
    </>
  )
}

export default Standings

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*This is to avoid issue with Mui slide transition creating scrollbars*/
  overflow: auto;
`
const Container = styled(motion.div)`
  /*TODO: this is temporary hack for group stage link*/
  /* padding-top: 44px;
  ${(p) => p.theme.breakpoints.up('md')} {
    padding-top: 68px;
  } */
  /* -end hack- */
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: flex-end;
  /*This is to avoid issue with Mui slide transition creating scrollbars*/
  overflow: hidden;
  /*This is to avoid issue with Mui slide transition creating scrollbars*/
  min-height: 100%;
  ${dodgeFab({ contentWidth: 800 })}
  /*TODO: temporary for top link thing */
  @media (min-width: 800px) {
    padding-bottom: ${spacing(2)};
  }
`
// const GroupCardWrapper = styled(motion.div)`
//   position: absolute;
//   top: 0;
//   display: flex;
//   justify-content: center;
//   width: 100%;
// `
// const GroupStageLink = styled(Card)`
//   padding: ${spacing(1)};
//   & {
//     margin-bottom: 0;
//   }
//   background-color: ${(p) => p.theme.palette.background.paper}e3;
// `
const westRowCss = css`
  tr:nth-child(1) {
    background-color: ${(p) => p.theme.palette.primary.main}33;
    ${dashedBorder}
  }
  tr:nth-child(n + 2):nth-child(-n + 4) {
    background-color: ${(p) =>
      color(p.theme.palette.secondary.light)
        .alpha(0.07)
        .string()};
  }
  tr:nth-child(4) {
    ${dashedBorder}
  }
  tr:nth-child(7) {
    border-bottom: 2px solid #fff;
  }
  /*non-playoff spots*/
  tr:nth-child(n + 8) {
    background-color: ${(p) => p.theme.palette.background.default}55;
    opacity: 0.85;
  }
  /* tr:last-child {
    border: none;
  } */
`
const eastRowCss = css`
  tr:nth-child(1) {
    background-color: ${(p) => p.theme.palette.primary.main}33;
    ${dashedBorder}
  }
  tr:nth-child(n + 2):nth-child(-n + 4) {
    background-color: ${(p) =>
      color(p.theme.palette.secondary.light)
        .alpha(0.07)
        .string()};
  }
  tr:nth-child(4) {
    ${dashedBorder}
  }
  tr:nth-child(10) {
    border-bottom: 2px solid #fff;
  }
  /*non-playoff spots*/
  tr:nth-child(n + 11) {
    background-color: ${(p) => p.theme.palette.background.default}55;
    opacity: 0.85;
  }
  /* tr:last-child {
    border: none;
  } */
`

const query = graphql`
  query MlsStandingsData {
    allStandings {
      nodes {
        east {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
        west {
          club
          rank
          pts
          ppg
          gp
          w
          l
          t
          gf
          ga
          gd
          form
        }
      }
    }
  }
`
