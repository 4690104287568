import React from 'react'
import useStandingsBackground from './use-standings-background'
import useStandingsData from 'src/features/standings/use-standings-data'
import GeneralBackground from '../common/general-background'

type Bgs = ReturnType<typeof useStandingsBackground>['bgs'][number]

const StandingsBackground = () => {
  const determineBgFromRank = (rank: number): Bgs => {
    if (rank < 1) return 'first'
    if (rank >= 1 && rank < 4) return 'home-playoffs'
    if (rank >= 4 && rank < 7) return 'away-playoffs'
    if (rank >= 7 && rank < 13) return 'no-playoffs'
    return 'last'
  }

  const standingsData = useStandingsData()
  const { rank } = standingsData.east.find((club) =>
    club.club.includes('Atlanta')
  ) || { rank: 1 }

  const { bgFluidData } = useStandingsBackground(determineBgFromRank(rank))

  return <GeneralBackground fluidData={bgFluidData} />
}

export default StandingsBackground
