import { useStaticQuery, graphql } from 'gatsby'
// import { StandingsBackgroundsQuery } from 'types/graphql-types'

//these should correspond to the appropriate filenames, obvs
const backgroundOptions = [
  'cupCelebration2',
  'first',
  'home-playoffs',
  'away-playoffs',
  // 'playoffs',
  'no-playoffs',
  'last',
] as const
export type BackgroundOptions = typeof backgroundOptions[number]

const useStandingsBackground = (whichBackground?: BackgroundOptions) => {
  const { allFile } = useStaticQuery<any>(graphql`
    query StandingsBackgrounds {
      allFile(
        filter: {
          relativeDirectory: { eq: "standings-bgs" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 30, traceSVG: { color: "#A19060" }) {
              originalName
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `)

  //!bad abstraction is bad
  const bgIndex = whichBackground
    ? allFile.nodes.findIndex(
        (bg: any) =>
          bg.childImageSharp?.fluid?.originalName === `${whichBackground}.png`
      )
    : 0

  const bgs: BackgroundOptions[] = []
  allFile.nodes.forEach((node: any) =>
    bgs.push(
      node.childImageSharp?.fluid?.originalName?.replace(
        '.png',
        ''
      ) as BackgroundOptions
    )
  )
  bgs.sort()

  return {
    bgFluidData: allFile.nodes[bgIndex].childImageSharp?.fluid,
    bgs,
  }
}

export default useStandingsBackground
